<template>
  <div class="page-wrapper-shopify">
    <div
      class="supplier-registeration page-wrapper-shopify p-0"
      :class="{ 'is-full-screen': isShopify }"
    >
      <div class="container-fluid">
        <div class="row" v-if="true">
          <div class="col-sm-12 pb-4">
            <div class="steps-box">
              <div class="body">
                <div class="each-step" @click="changeStep('GeneralInfo')">
                  <div
                    class="button-icon"
                    :class="step == 'GeneralInfo' ? 'active' : ''"
                  >
                    <span
                      class="material-icons icon"
                      :class="step == 'GeneralInfo' ? 'active' : ''"
                    >
                      arrow_right_alt
                    </span>
                  </div>
                  <span
                    class="button-title"
                    :class="step == 'GeneralInfo' ? 'active' : ''"
                  >
                    <span class="step-title" v-show="step == 'GeneralInfo'">
                      Step 1:
                    </span>
                    General info
                  </span>
                </div>

                <div class="each-step" @click="changeStep('ShippingInfo')">
                  <div
                    class="button-icon"
                    :class="step == 'ShippingInfo' ? 'active' : ''"
                  >
                    <span
                      class="material-icons icon"
                      :class="step == 'ShippingInfo' ? 'active' : ''"
                    >
                      arrow_right_alt
                    </span>
                  </div>
                  <span
                    class="button-title"
                    :class="step == 'ShippingInfo' ? 'active' : ''"
                  >
                    <span class="step-title" v-show="step === 'ShippingInfo'">
                      Step 2:
                    </span>
                    Shipping info
                  </span>
                </div>
                <div class="each-step" @click="changeStep('CatalogManagement')">
                  <div
                    class="button-icon"
                    :class="step == 'CatalogManagement' ? 'active' : ''"
                  ></div>
                  <span
                    class="button-title"
                    :class="step == 'CatalogManagement' ? 'active' : ''"
                  >
                    <span
                      class="step-title"
                      v-show="step === 'CatalogManagement'"
                    >
                      Step 3:
                    </span>
                    Catalog management
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 py-0">
            <div class="forms-box">
              <div v-if="step == 'GeneralInfo'">
                <div class="body p-0">
                  <GeneralInfo ref="GeneralInfo" @change-step="changeStep" />
                </div>
                <div class="footer d-flex align-items-center">
                  <p class="desc mr-auto ml-0">
                    You can edit the information later, but we need the form to
                    publish your products on VGANG.
                  </p>
                  <button
                    @click="$refs.GeneralInfo.SaveChanges()"
                    v-if="step == 'GeneralInfo'"
                    :disabled="FinanceButtonIsDisabled"
                    :class="FinanceButtonIsDisabled ? 'disabled' : ''"
                    class="d-flex Polaris-Button Polaris-Button--primary mr-3"
                    type="button"
                  >
                    <span class="Polaris-Button__Content">
                      <span class="Polaris-Button__Text">Save & Next</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 py-0">
            <div class="forms-box">
              <div v-if="step == 'ShippingInfo'">
                <div class="body p-0">
                  <ShippingInfo @change-step="changeStep" ref="ShippingInfo" />
                </div>
                <div class="footer d-flex align-items-center">
                  <p class="desc mr-auto ml-0">
                    You can edit the information later, but we need the form to
                    publish your products on VGANG.
                  </p>
                  <button
                    @click="validateShippingInfo()"
                    v-if="step == 'ShippingInfo'"
                    :disabled="FinanceButtonIsDisabled"
                    :class="FinanceButtonIsDisabled ? 'disabled' : ''"
                    class="d-flex Polaris-Button Polaris-Button--primary mr-3"
                    type="button"
                  >
                    <span class="Polaris-Button__Content" loading="true">
                      <span class="Polaris-Button__Text">Save & Next</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 py-0">
            <div class="forms-box">
              <div v-if="step == 'CatalogManagement'">
                <div class="body p-0">
                  <CatalogManagement
                    @change-step="changeStep"
                    ref="CatalogManagement"
                  />
                </div>
                <div class="footer d-flex align-items-center">
                  <p class="desc mr-auto ml-0">
                    You can edit the information later, but we need the form to
                    publish your products on VGANG.
                  </p>
                  <button
                    v-if="step == 'CatalogManagement'"
                    :disabled="FinanceButtonIsDisabled"
                    :class="FinanceButtonIsDisabled ? 'disabled' : ''"
                    class="d-flex Polaris-Button Polaris-Button--primary mr-3"
                    type="button"
                    @click="$refs.CatalogManagement.SaveChanges()"
                  >
                    <span class="Polaris-Button__Content">
                      <span class="Polaris-Button__Text">Save & Next</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-sm-12">
            <div class="loading-wrapper">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SellerProducts from "@/services/SellerProducts";
import CustomInput from "@/components/CustomInput.vue";
import GeneralInfo from "./GeneralInformation.vue";
import ShippingInfo from "./ShippingInfo.vue";
import CatalogManagement from "./CatalogManagement.vue";
export default {
  emits: ["change-step"],
  components: {
    CustomInput,
    GeneralInfo,
    ShippingInfo,
    CatalogManagement,
  },
  data() {
    return {
      step: "GeneralInfo",
      isShopify: false,
      FinanceButtonIsDisabled: false,
      stepsValidations: {
        isValidShippingInfo: false,
      },
    };
  },
  methods: {
    changeStep(step) {
      this.step = step;
    },
    async validateShippingInfo() {
      const isValid = await this.$refs.ShippingInfo.validate();
      if (isValid) {
        this.stepsValidations = isValid;
        this.step = "CatalogManagement";
      } else {
        this.stepsValidations = false;
      }
    },

    submitFinancialInformation(step) {
      const params = {
        discountPercentage: parseInt(this.discount.percentage),
        discountCode: this.discount.code,
      };
      RegisterationSetup.submitFinancialInformationStep(params, step)
        .then((response) => {
          this.step = response.data.step;
          if (this.step == "Complete") {
            this.$router.push("/supplier/assign-categoryV2");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    if (process.env.VUE_APP_SOURCE_NAME == "shopifyimporter") {
      this.isShopify = true;
      SellerProducts.startImportProducts();
    }
  },
};
</script>
